import {
  Alternative,
  InvestmentType,
  PendingHintCode,
  FailedHintCode,
} from "@lysaab/ui-2";
import { KFPresaleInfo } from "../../../../../data/dataCreateAccount";
import {
  AccountQuestions,
  RiskImportance,
  RiskPropensity,
  RiskReaction,
} from "../../../../../data/dataInvestments";
import { createContext } from "../../../../../context/CreateContext";
import {
  DepositInterval,
  PurposeAccountCorporation,
  WithdrawalInterval,
} from "../../../../../data/dataKyc";
import { InsuranceCreationToken } from "../../../../../data/dataLife";
import { BankidPollResponse } from "../../../../../data/dataBankid";

export const MIN_RECOMMENDED_ALLOCATION = 20;

/**
 * This is the state for all generic sweden data.
 */
export type KFAccountState = Partial<AccountQuestions> & {
  ongoing: boolean;
  presaleInfo?: KFPresaleInfo;

  /** Intro */
  hasNeed?: Alternative<boolean>;
  hasExperience?: Alternative<boolean>;
  knowsRights?: Alternative<boolean>;
  knowsFee?: Alternative<boolean>;
  understoodRights?: boolean;
  understoodFee?: boolean;

  /** Lysa kyc */
  depositInterval?: DepositInterval;
  withdrawalInterval?: WithdrawalInterval;
  purpose: PurposeAccountCorporation[];

  /** Risk */
  riskImportance?: Alternative<RiskImportance>;
  riskReaction?: Alternative<RiskReaction>;
  riskPropensity?: Alternative<RiskPropensity>;

  /** Allocation */
  allocationSelectedRisk?: number;
  allocationInvestment: string;
  allocationMonthlyInvestment: string;
  allocationSelectedYear?: Alternative<string>;

  /** Investment Type */
  investmentType?: InvestmentType;

  /** Advice */
  takenRisk?: number;
  advicedRisk?: number;
  declaration?: string;
  allocationForcedRisk?: number;

  /** Candidate */
  identificationNumber?: string;

  creationToken: InsuranceCreationToken;

  /** Signing */
  currentSignee?: number;
  signingStatus?: BankidPollResponse;
  autostartToken?: string;
};

export function getBankIDMessages() {
  return () => ({
    qrInfo1: "Öppna BankID-appen",
    qrInfo2: 'Klicka på "QR-kod"',
    qrInfo3: "Rikta kamerna mot QR-koden",
    divider: "",
    buttonOpen: "Öppna BankID på denna enhet",
    buttonErrorHeader: "Något gick fel!",
    buttonRetry: "Försök igen",
  });
}

export const pendingBankIDMessages = {
  [PendingHintCode.NOCLIENT]: "Väntar på legitimering...",
  [PendingHintCode.OUTSTANDINGTRANSACTION]: "Väntar på legitimering...",
  [PendingHintCode.STARTED]: "Väntar på legitimering...",
  [PendingHintCode.USERSIGN]: "Väntar på legitimering...",
};

export const failedBankIDMessages = {
  [FailedHintCode.CANCELLED]: " ",
  [FailedHintCode.CERTIFICATEERR]: " ",
  [FailedHintCode.EXPIREDTRANSACTION]: " ",
  [FailedHintCode.STARTFAILED]: " ",
  [FailedHintCode.USERCANCEL]: " ",
};

export const getDefaultKFState = (): KFAccountState => ({
  ongoing: false,
  allocationInvestment: "10000",
  allocationMonthlyInvestment: "1000",
  purpose: [],
  creationToken: crypto.randomUUID() as InsuranceCreationToken,
});

export const [CreateKFAccountProvider, useCreateKFAccount] =
  createContext<KFAccountState>("CreateKFAccountContext", getDefaultKFState());
