import React, {
  useRef,
  useContext,
  useState,
  useEffect,
  VoidFunctionComponent,
} from "react";
import {
  Card,
  Button,
  Form,
  LysaFormRef,
  Slider,
  InvestmentType,
  ExpandableSneakPeakCard,
  AllocationBarIntl,
} from "@lysaab/ui-2";
import { EditAllocationForecast } from "./EditAllocationForecast";
import { EditAllocationFees } from "./EditAllocationFees";
import { EditAllocationPositions } from "./EditAllocationPositions";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { HorizonWarning } from "./HorizonWarning";
import { DEFAULT_VALUES } from "./DefaultValues";
import { LocalizationContext } from "../../context/LocalizationContext";
import { SavingsHorizonLength } from "../../data/dataInvestments";
import { InvestmentDisclaimer } from "../../components/investmentDisclaimer/InvestmentDisclaimer";
import "./EditAllocation.scss";
import { InvestmentInput } from "./InvestmentInput";
import { FormattedPercentage } from "../../components/formattedPercentage/FormattedPercentage";
import { AccountType } from "../../data/dataAccounts";
import { KfAllocationDissuasion } from "../../components/kfDissuasion/KfAllocationDissuasion";

const messages = defineMessages({
  toggleShowPositions: {
    id: "editallocationpositions.toggleshow",
  },
  toggleHidePositions: {
    id: "editallocationpositions.togglehide",
  },
  allocationBarStocks: {
    id: "editallocationpositions.card.bar.stocks",
  },
  allocationBarBonds: {
    id: "editallocationpositions.card.bar.bonds",
  },
});

interface Props {
  advisedRisk?: number;
  savingsHorizon?: SavingsHorizonLength;
  allocationSelectedRisk?: number;
  setAllocationSelectedRisk: (allocationSelectedRisk: number) => void;
  allocationInvestment?: number;
  setAllocationInvestment: (allocationInvestment: number) => void;
  investmentType: InvestmentType;
  accountType: AccountType;
  next: () => void;
  nextRiskWarning: () => void;
  navigateToHorizon?: () => void;
  insured?: string;
}

export const EditAllocation: VoidFunctionComponent<Props> = ({
  advisedRisk,
  savingsHorizon,
  allocationSelectedRisk,
  setAllocationSelectedRisk,
  allocationInvestment,
  setAllocationInvestment,
  investmentType,
  accountType,
  next,
  nextRiskWarning,
  navigateToHorizon,
  insured,
}) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const localizationContext = useContext(LocalizationContext);
  const [showHorizonWarning, setShowHorizonWarning] = useState(false);
  const [monthlyInvestment, setMonthlyInvestment] = useState<number>(
    DEFAULT_VALUES[localizationContext.state.currency].monthly
  );
  const [horizonYears, setHorizonYears] = useState(5);

  useEffect(() => {
    if (typeof allocationInvestment === "undefined") {
      setAllocationInvestment(
        DEFAULT_VALUES[localizationContext.state.currency].defaultInvestment
      );
    }
    if (
      typeof allocationSelectedRisk === "undefined" &&
      typeof advisedRisk !== "undefined"
    ) {
      setAllocationSelectedRisk(advisedRisk);
    }
  }, [
    localizationContext.state.currency,
    allocationInvestment,
    allocationSelectedRisk,
    setAllocationInvestment,
    setAllocationSelectedRisk,
    advisedRisk,
  ]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      savingsHorizon === SavingsHorizonLength.SHORT &&
      typeof allocationSelectedRisk === "number" &&
      allocationSelectedRisk > 50
    ) {
      setShowHorizonWarning(true);
      return;
    }

    if (
      formRef.current?.isValid &&
      typeof allocationSelectedRisk !== "undefined" &&
      typeof advisedRisk !== "undefined"
    ) {
      if (Math.abs(allocationSelectedRisk - advisedRisk) > 15) {
        nextRiskWarning();
      } else {
        next();
      }
    }
  };

  if (
    typeof allocationSelectedRisk === "undefined" ||
    typeof advisedRisk === "undefined" ||
    typeof allocationInvestment === "undefined"
  ) {
    return null;
  }

  return (
    <div className="edit-allocation-page">
      <Form lysaFormRef={formRef} onSubmit={onSubmit}>
        <Card>
          <div className="editallocation-header">
            <h3>
              <FormattedMessage id="editallocation.editallocation" />
            </h3>
            <Button
              variant="secondary"
              inline
              size="small"
              onClick={() => setAllocationSelectedRisk(advisedRisk)}
              className={
                allocationSelectedRisk === advisedRisk ? "reset-hidden" : ""
              }
              label={<FormattedMessage id="editallocation.button.reset" />}
            />
          </div>
          <div className="allocations">
            <strong>
              <FormattedMessage id="editallocation.text.lysassuggestion" />
            </strong>
            <AllocationBarIntl
              messages={{
                stocks: (
                  <>
                    <span className="allocation-bar-value">
                      <FormattedPercentage value={advisedRisk} />
                    </span>
                    <span className="allocation-bar-name">
                      {intl.formatMessage(messages.allocationBarStocks)}
                    </span>
                  </>
                ),
                bonds: (
                  <>
                    <span className="allocation-bar-name">
                      {intl.formatMessage(messages.allocationBarBonds)}
                    </span>
                    <span className="allocation-bar-value">
                      <FormattedPercentage value={100 - advisedRisk} />
                    </span>
                  </>
                ),
              }}
              risk={advisedRisk}
            />
            <Slider
              label={
                <>
                  <strong>
                    {allocationSelectedRisk === advisedRisk ? (
                      <FormattedMessage id="editallocation.text.targetallocation" />
                    ) : (
                      <FormattedMessage id="editallocation.text.targetallocation.new" />
                    )}
                  </strong>
                  {showHorizonWarning && (
                    <HorizonWarning navigateToHorizon={navigateToHorizon} />
                  )}
                  <div className="allocation-slider-value-wrapper">
                    <div>
                      <span className="allocation-bar-value">
                        <FormattedPercentage value={allocationSelectedRisk} />
                      </span>
                      <span className="allocation-bar-name">
                        {intl.formatMessage(messages.allocationBarStocks)}
                      </span>
                    </div>
                    <div>
                      <span className="allocation-bar-name">
                        {intl.formatMessage(messages.allocationBarBonds)}
                      </span>
                      <span className="allocation-bar-value">
                        <FormattedPercentage
                          value={100 - allocationSelectedRisk}
                        />
                      </span>
                    </div>
                  </div>
                </>
              }
              min={0}
              max={100}
              value={allocationSelectedRisk ?? 0}
              onChange={(value) => {
                if (
                  savingsHorizon === SavingsHorizonLength.SHORT &&
                  value > 50
                ) {
                  setShowHorizonWarning(true);
                  setAllocationSelectedRisk(50);
                } else {
                  setShowHorizonWarning(false);
                  setAllocationSelectedRisk(value);
                }
              }}
              risk
            />
          </div>
        </Card>

        <KfAllocationDissuasion
          accountType={accountType}
          risk={allocationSelectedRisk}
          advicedRisk={advisedRisk}
        />

        <Card>
          <h3>
            <FormattedMessage id="editallocation.header.forecast" />
          </h3>
          <h4>
            <FormattedMessage id="editallocation.header.example-investment" />
          </h4>
          <InvestmentInput
            allocationInvestment={allocationInvestment}
            setAllocationInvestment={setAllocationInvestment}
          />
          <EditAllocationForecast
            chosenAllocation={allocationSelectedRisk.toString()}
            allocationInvestment={allocationInvestment}
            monthlyInvestment={monthlyInvestment}
            setMonthlyInvestment={setMonthlyInvestment}
            horizonYears={horizonYears}
            setHorizonYears={setHorizonYears}
          />
        </Card>
        <Button
          block
          type="submit"
          label={<FormattedMessage id="editallocation.button.next" />}
        />

        <EditAllocationFees
          risk={allocationSelectedRisk}
          investmentType={investmentType}
          accountType={accountType}
          allocationInvestment={allocationInvestment}
          setAllocationInvestment={setAllocationInvestment}
          monthlyInvestment={monthlyInvestment}
          setMonthlyInvestment={setMonthlyInvestment}
          horizonYears={horizonYears}
          setHorizonYears={setHorizonYears}
          insured={insured}
        />
        <ExpandableSneakPeakCard
          buttonOpenText={intl.formatMessage(messages.toggleShowPositions)}
          buttonCloseText={intl.formatMessage(messages.toggleHidePositions)}
        >
          <h4>
            <FormattedMessage id="editallocationpositions.header" />
          </h4>
          <EditAllocationPositions
            risk={allocationSelectedRisk}
            allocationInvestment={allocationInvestment}
            investmentType={investmentType}
          />
        </ExpandableSneakPeakCard>
      </Form>
      <InvestmentDisclaimer />
    </div>
  );
};
