import React, { VoidFunctionComponent } from "react";
import { AnimateHeight } from "../animateHeight/AnimateHeight";
import { AccountType } from "../../data/dataAccounts";
import { SNACKBAR_TYPES, Snackbar } from "@lysaab/ui-2";
import { Link } from "react-router-dom";
import { getNavLink } from "../../hooks/useCountryUrls";
import { CREATE_ACCOUNT_CORPORATION_PAGE_URL } from "../../countries/sweden/pages/corporation/kf/CreateAccountCorporationPage";
import { SavingsHorizonLength } from "../../data/dataInvestments";

const MIN_RECOMMENDED_ALLOCATION = 20;

interface Props {
  accountType: AccountType;
  horizonLength?: SavingsHorizonLength;
  onClick?: () => void;
}

export const KfHorizonDissuasion: VoidFunctionComponent<Props> = ({
  accountType,
  horizonLength,
  onClick,
}) => {
  if (
    accountType !== AccountType.DANICA_KF &&
    accountType !== AccountType.KF_SWE
  ) {
    return null;
  }

  return (
    <AnimateHeight isOpen={horizonLength === SavingsHorizonLength.SHORT}>
      <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
        <div>
          <b>Avrådan på grund av Lysas roll som försäkringsförmedlare:</b>{" "}
          eftersom kapitalförsäkring är en schablonbeskattad sparform bör
          spararen ha en investering som över tid kan förväntas leverera en
          avkastning som innebär att beskattningsformens fördel tillgodogörs.
          Din investeringhorisont innebär att du sannolikt kommer få ett
          aktieinnehav som understiger <b>{MIN_RECOMMENDED_ALLOCATION} %</b>. En
          värdepappersdepå kan därför vara mer fördelaktig än en
          kapitalförsäkring.
          <br />
          <b>
            <Link
              to={getNavLink(CREATE_ACCOUNT_CORPORATION_PAGE_URL)}
              onClick={() => {
                onClick?.();
              }}
            >
              Öppna värdepappersdepå
            </Link>
          </b>
        </div>
      </Snackbar>
    </AnimateHeight>
  );
};
