import React from "react";
import {
  Card,
  Button,
  InvestmentType,
  AllocationBarIntl,
  SNACKBAR_TYPES,
  Spinner,
  RiskIndicator,
  Snackbar,
  Typography,
} from "@lysaab/ui-2";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import {
  AdviceResponse,
  AccountQuestions,
  isValidAccountQuestions,
  Eligibility,
  RecursivePartial,
} from "../../data/dataInvestments";
import { InvestmentDisclaimer } from "../../components/investmentDisclaimer/InvestmentDisclaimer";
import "./Advise.scss";
import {
  GridRow,
  JustifyContentValues,
} from "../../components/grid/gridRow/GridRow";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { SuitabilityDownload } from "./SuitabilityDownload";
import { AccountType } from "../../data/dataAccounts";
import { TranslatedText } from "../../components/TranslatedText";

export enum AdviceState {
  INITIAL,
  LOADING,
  DONE,
  ERROR,
}

interface MessageWithId {
  id: string;
}

const investmentTypeMessages = defineMessages<
  InvestmentType,
  MessageWithId,
  Record<InvestmentType, MessageWithId>
>({
  [InvestmentType.BROAD]: {
    id: "advice.card.investmenttype.BROAD",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "advice.card.investmenttype.SUSTAINABLE",
  },
});

interface Props {
  investmentType: InvestmentType;
  advise?: AdviceResponse;
  retryMsg?: string;
  next: () => void;
  navigateToEditAllocation: () => void;
  navigateToFees: () => void;
  retry: () => void;
  accountQuestions: Partial<AccountQuestions>;
  eligibility?: RecursivePartial<Eligibility>;
  accountType: AccountType;
}

export const Advise: React.FC<Props> = ({
  investmentType,
  advise,
  retryMsg,
  next,
  navigateToEditAllocation,
  navigateToFees,
  retry,
  accountQuestions,
  eligibility,
  accountType,
}) => {
  const intl = useIntl();
  const allocationBarLabels = {
    stocks: (
      <>
        <span className="allocation-bar-value">
          {intl.formatNumber((advise?.advisedRisk || 0) / 100, {
            style: "percent",
          })}
        </span>
        <span className="allocation-bar-name">
          <FormattedMessage id="advice.bar.stocks" />
        </span>
      </>
    ),
    bonds: (
      <>
        <span className="allocation-bar-name">
          <FormattedMessage id="advice.bar.bonds" />
        </span>
        <span className="allocation-bar-value">
          {intl.formatNumber((100 - (advise?.advisedRisk || 0)) / 100, {
            style: "percent",
          })}
        </span>
      </>
    ),
  };

  return (
    <div className="advice-page">
      <h2>
        <FormattedMessage id="advice.header" />
      </h2>
      {retryMsg ? (
        <div>
          <Snackbar type={SNACKBAR_TYPES.ERROR}>{retryMsg}</Snackbar>
          <Button
            variant="primary"
            onClick={retry}
            block
            label={<FormattedMessage id="advice.button.retry" />}
          />
        </div>
      ) : advise ? (
        <>
          <section className="allocation-proposal">
            <h3>
              <FormattedMessage id="advice.allocation.card.allocationbar.header" />
            </h3>
            <AllocationBarIntl
              risk={advise.advisedRisk}
              messages={allocationBarLabels}
              data-test-id="allocation-bar"
            />
            <GridRow justifyContent={JustifyContentValues.SPACE_BETWEEN}>
              <GridCol disabledFlexGrow>
                <Typography type="label-large">
                  <FormattedMessage id="advice.card.investmenttype.title" />
                </Typography>
              </GridCol>
              <GridCol
                disabledFlexGrow
                className="allocation-proposal-risk-indicator"
              >
                {intl.formatMessage(investmentTypeMessages[investmentType])}{" "}
                <RiskIndicator
                  risk={advise.advisedRisk}
                  investmentType={investmentType || InvestmentType.BROAD}
                />
              </GridCol>
            </GridRow>
            <div className="allocation-proposal-investment-type">
              {investmentType === InvestmentType.BROAD ? (
                <FormattedMessage
                  id="advice.card.investmenttype.broad.body"
                  values={{
                    nbr: (text: string) => {
                      return intl
                        .formatNumber(Number.parseInt(text, 10))
                        .replace(/ /g, "&nbsp;");
                    },
                  }}
                />
              ) : (
                <FormattedMessage
                  id="advice.card.investmenttype.sustainable.body"
                  values={{
                    nbr: (text: string) => {
                      return intl
                        .formatNumber(Number.parseInt(text, 10))
                        .replace(/ /g, "&nbsp;");
                    },
                  }}
                />
              )}
            </div>
          </section>

          <section>
            <Button
              onClick={next}
              block
              data-test-id="advice-next"
              label={<FormattedMessage id="advice.button.next" />}
            />
            <Button
              variant="secondary"
              block
              type="button"
              onClick={navigateToEditAllocation}
              label={<FormattedMessage id="advice.button.change.allocation" />}
            />

            <Button
              variant="secondary"
              block
              type="button"
              onClick={navigateToFees}
              label={<FormattedMessage id="advice.button.details" />}
            />
          </section>
          <section>
            <Card className="box-margin">
              <h4>
                <FormattedMessage id="advice.declaration.heading" />
              </h4>
              <p>{advise.declaration}</p>
              {(accountType === AccountType.DANICA_KF ||
                accountType === AccountType.KF_SWE) && (
                <div className="kf-info">
                  <p>
                    <TranslatedText id="advice.declaration.insurance.paragraph" />
                  </p>
                </div>
              )}
            </Card>
          </section>
          {isValidAccountQuestions(accountQuestions) && (
            <SuitabilityDownload
              esgQuestions={advise.esgResult}
              accountQuestions={accountQuestions}
              advisedRisk={advise.advisedRisk}
              eligibility={eligibility}
            />
          )}
          <div className="box-margin">
            <InvestmentDisclaimer />
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
