import React, { useEffect, useRef, useState } from "react";
import { useCreateKFAccount } from "../KFAccountContext";
import {
  LysaFormRef,
  Form,
  Card,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { HasNeed } from "./components/HasNeed";
import { HasExperience } from "./components/HasExperience";
import { KnowsRights } from "./components/KnowsRights";
import { KnowsFee } from "./components/KnowsFee";
import { UnderstoodRights } from "./components/UnderstoodRights";
import { UnderstoodFee } from "./components/UnderstoodFee";
import {
  dataLife,
  GetPreconditionsResponse,
  InsuranceCreationToken,
  Precondition,
} from "../../../../../../data/dataLife";
import {
  ContactEmailAddresses,
  ContactPhoneNumbers,
} from "../../../../../../components/Contact";
import { useLocalizationContext } from "../../../../../../context/LocalizationContext";

interface Props {
  next: () => void;
  toSigning: (token: InsuranceCreationToken) => void;
}

export const KfBody: React.FunctionComponent<Props> = ({ next, toSigning }) => {
  const [KFAccount, setKFAccount] = useCreateKFAccount();
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const [precondition, setPrecondition] = useState<GetPreconditionsResponse>();
  const localizationContext = useLocalizationContext();
  const country = localizationContext.state.country;

  useEffect(() => {
    dataLife.getPreconditions().then(setPrecondition);
  }, []);

  if (!precondition || !country) {
    return <Spinner />;
  }

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={(event) => {
        if (formRef.current?.isValid !== true) {
          return;
        }

        if (KFAccount.hasNeed?.value !== true) {
          return;
        }

        next();
      }}
    >
      <Card>
        <h2>
          <TranslatedText id="sweden.kf.intro.header" />
        </h2>
        <p>
          <TranslatedText id="sweden.kf.intro.offer" />
        </p>
        <p>
          <TranslatedText id="sweden.kf.intro.legal" />
        </p>
        <h3>
          <TranslatedText id="sweden.kf.intro.need.header" />
        </h3>

        {precondition.precondition === Precondition.OK && (
          <React.Fragment>
            <HasNeed />

            {KFAccount.hasNeed?.value === false && (
              <Snackbar type={SNACKBAR_TYPES.ERROR}>
                <TranslatedText id="sweden.kf.intro.need.error" />
              </Snackbar>
            )}

            {KFAccount.hasNeed?.value === true && (
              <div>
                <h3>
                  <TranslatedText id="sweden.kf.intro.fit.header" />
                </h3>
                <p>
                  <TranslatedText id="sweden.kf.intro.fit.paragraph" />
                </p>

                <HasExperience />
                <KnowsRights />

                {KFAccount.knowsRights?.value === false && (
                  <React.Fragment>
                    <p>
                      <TranslatedText id="sweden.kf.intro.rights-info.paragraph" />
                    </p>
                    <UnderstoodRights />
                  </React.Fragment>
                )}

                <KnowsFee />
                {KFAccount.knowsFee?.value === false && (
                  <React.Fragment>
                    <p>
                      <TranslatedText
                        id="sweden.kf.intro.fee-info.paragraph"
                        values={{
                          insured: intl.formatNumber(1.01, {
                            style: "percent",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }),
                          fee: intl.formatNumber(0.002, {
                            style: "percent",
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                          }),
                        }}
                      />
                    </p>
                    <UnderstoodFee />
                  </React.Fragment>
                )}
              </div>
            )}

            <Button
              block
              type="submit"
              label={<TranslatedText id="sweden.kf.link" />}
            />
          </React.Fragment>
        )}

        {precondition.precondition ===
          Precondition.PENDING_INSURANCE_APPLICATION && (
          <React.Fragment>
            <Snackbar type={SNACKBAR_TYPES.INFO}>
              <TranslatedText id="sweden.kf.ongoing" />
            </Snackbar>
            <Button
              block
              onClick={() => {
                setKFAccount({
                  creationToken: precondition.creationToken,
                });
                toSigning(precondition.creationToken);
              }}
              label={<TranslatedText id="sweden.kf.ongoing.continue" />}
            />
          </React.Fragment>
        )}

        {precondition.precondition ===
          Precondition.NOT_SUPPORTED_CORPORATION_TYPE && (
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <>
              <p>
                <TranslatedText id="sweden.kf.not-supported.paragraph" />
              </p>
              <p>
                <TranslatedText
                  id="sweden.kf.not-supported.contact"
                  values={{
                    phone: () => {
                      const phoneNumber = intl.formatMessage(
                        ContactPhoneNumbers[country]
                      );
                      return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
                    },
                    email: () => {
                      const emailAddress = intl.formatMessage(
                        ContactEmailAddresses[country]
                      );
                      return (
                        <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                      );
                    },
                  }}
                />
              </p>
            </>
          </Snackbar>
        )}
      </Card>
    </Form>
  );
};
