import {
  createIntl,
  createIntlCache,
  CustomFormats,
  RawIntlProvider,
} from "react-intl";
import React, { useContext } from "react";
import "./App.scss";
import { LocalizationContext, Language } from "./context/LocalizationContext";
import { LayoutContext } from "./context/LayoutContext";
import { FlashOverlay } from "@lysaab/ui-2";
import cx from "classnames";
import svMessages from "./locales/sv.json";
import deMessages from "./locales/de.json";
import enMessages from "./locales/en.json";
import { ScrollOnNavigation } from "./components/scroll/ScrollOnNavigation";
import { IsAuthenticatedBoundary } from "./IsAuthenticatedBoundary";
import { Store } from "./Store";
import { PersistSettings } from "./components/persistSettings/PersistSettings";
import { LogoutWarning } from "./components/logoutWarning/LogoutWarning";
import { ImpersonationBar } from "./components/impersonationBar/ImpersonationBar";
import { SystemMessages } from "./components/systemMessages/SystemMessages";
import { usePageViewTracker } from "./hooks/usePageviewTracker";
import { BurgerMenu } from "./components/burgerMenu/BurgerMenu";
import { Metadata } from "./Metadata";
import { CookieConsent } from "./pages/overview/CookieConsent";
import { useTrackingCategories } from "./hooks/useTrackingCategories";
import { YearlyReviewInfo } from "./components/yearlyReviewInfo/YearlyReviewInfo";
import { useInitNotifications } from "./hooks/useNotifications";
import { LysaCountry } from "@lysaab/countries";
import { EventBars } from "./components/eventBars/EventBars";
import { ElevioLoader } from "./components/elevio/ElevioLoader";
import { CmdK } from "./components/cmdK/CmdK";
import { ErrorBoundary } from "@lysaab/boundary-pages";
import { useDarkMode } from "./hooks/useDarkmode";
import { useActivityTracker } from "./hooks/useActivityTracker";

window.s = Store;

interface Props {
  children: React.ReactNode;
}

const timeZones: Record<LysaCountry, string> = {
  [LysaCountry.SWEDEN]: "Europe/Stockholm",
  [LysaCountry.DENMARK]: "Europe/Stockholm",
  [LysaCountry.FINLAND]: "Europe/Helsinki",
  [LysaCountry.GERMANY]: "Europe/Stockholm",
  [LysaCountry.SPAIN]: "Europe/Madrid",
};

const languagePacks: Record<Language, Record<string, string>> = {
  [Language.ENGLISH]: enMessages,
  [Language.GERMAN]: deMessages,
  [Language.SWEDISH]: svMessages,
};

const formats: CustomFormats = {
  number: {
    SEK: {
      style: "currency",
      currency: "SEK",
    },
  },
};

export const App: React.FC<Props> = React.memo(({ children }) => {
  const localizationContext = useContext(LocalizationContext);
  const { state: layoutState } = useContext(LayoutContext);
  const { pageStripped, showCookieConsent, hideNav } = layoutState;

  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale: localizationContext.getLocale(),
      messages: languagePacks[localizationContext.state.language],
      formats: formats,
      defaultFormats: formats,
      timeZone: localizationContext.state.country
        ? timeZones[localizationContext.state.country]
        : timeZones.SE,
      onError: (err) => {
        return;
      },
    },
    cache
  );

  useDarkMode();
  useActivityTracker();
  usePageViewTracker();
  useTrackingCategories();
  useInitNotifications();

  const classes = cx("lysa-web-app", {
    "stripped-view": pageStripped,
    "show-baked-dough-consent": showCookieConsent,
    "is-authenticated": true,
    "hide-nav": hideNav,
    "see-no-evil": layoutState.seeNoEvil,
  });

  return (
    <RawIntlProvider value={intl}>
      <ErrorBoundary intl={intl}>
        <FlashOverlay>
          <IsAuthenticatedBoundary>
            <CmdK>
              <SystemMessages />
              <ImpersonationBar />
              <EventBars />
              <ScrollOnNavigation />
              <PersistSettings />
              <Metadata />
              <div className={classes}>
                <BurgerMenu>{children}</BurgerMenu>
                <CookieConsent />
              </div>
            </CmdK>
          </IsAuthenticatedBoundary>
          <LogoutWarning />
          <YearlyReviewInfo />
          <ElevioLoader />
        </FlashOverlay>
      </ErrorBoundary>
    </RawIntlProvider>
  );
});
