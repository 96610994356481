import React, { ComponentProps, FunctionComponent, useContext } from "react";
import { Button, Card, Icon, SNACKBAR_TYPES, Snackbar } from "@lysaab/ui-2";
import { KfBody } from "./KfBody";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import "./Intro.scss";
import { UserContext } from "../../../../../../context/UserContext";
import { Link, Redirect } from "react-router-dom";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { CREATE_ACCOUNT_URL } from "../../../../../../pages/createAccount/CreateAccountStory";
import { LOGIN_SWEDEN_PAGE_URL } from "../../../LoginPage";
import { useIsReadOnly } from "../../../../../../hooks/useIsReadOnly";

interface Props
  extends Pick<ComponentProps<typeof KfBody>, "next" | "toSigning"> {}

export const Intro: FunctionComponent<Props> = ({ next, toSigning }) => {
  const userContext = useContext(UserContext);
  const user = userContext.state;
  const isReadOnly = useIsReadOnly();

  if (!user.name) {
    return <Redirect to={LOGIN_SWEDEN_PAGE_URL} />;
  }

  return (
    <div className="create-account-corporation-intro">
      <h1>
        <TranslatedText id="sweden.kf.new" />
      </h1>
      {isReadOnly === false && (
        <React.Fragment>
          <div className="intro-ingress">
            <Icon.Checkmark />
            <TranslatedText id="sweden.kf.ingress" />
          </div>

          <KfBody next={next} toSigning={toSigning} />

          <Card>
            <h2>
              <TranslatedText id="sweden.vp" />
            </h2>
            <p>
              <TranslatedText id="sweden.vp.ingress" />
            </p>
            <div className="bottom-nav">
              <Button
                component={Link}
                block
                to={getNavLink(CREATE_ACCOUNT_URL)}
                label={<TranslatedText id="sweden.vp.link" />}
              />
            </div>
          </Card>
        </React.Fragment>
      )}

      {isReadOnly === true && (
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <TranslatedText id="sweden.kf.read-only-error" />
        </Snackbar>
      )}
    </div>
  );
};
