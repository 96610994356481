import { Button, SNACKBAR_TYPES, Snackbar, Story } from "@lysaab/ui-2";
import { OVERVIEW_PAGE_URL } from "../../../../../../pages/overview/OverviewPage";
import { useSafeNavigation } from "../../../../../../hooks/useSafeNavigation";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { Link } from "react-router-dom";

export const MIGRATION_REQUEST_KF_PAGE_URL = "/migration/kf/request";
export const MIGRATION_REQUEST_KF_EXTENDED_PAGE_URL =
  "/migration/kf/request-extended";

export function MigrationKfRequestPage() {
  const safeNavigation = useSafeNavigation();
  return (
    <Story
      ariaLabelProgress={() => "Progress done"}
      header={"Flytt av kapitalförsäkring"}
      onExit={() => {
        safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
      }}
      showClose={true}
      showBack={false}
      transitionKey={"0"}
      progress={100}
    >
      <Snackbar type={SNACKBAR_TYPES.INFO} {...{ order: 0 }}>
        Det är tyvärr inte längre möjligt att flytta kapitelförsäkringar till
        Lysa Life innan årskiftet.
      </Snackbar>
      <Button
        block
        component={Link}
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label="Till översikten"
      />
    </Story>
  );
}
