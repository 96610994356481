import {
  Form,
  LysaFormRef,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
} from "@lysaab/ui-2";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { generatePath, Redirect, useHistory, useLocation } from "react-router";
import {
  InvestmentAccount,
  dataAccounts,
  InvestmentAccountId,
} from "../../../../../../data/dataAccounts";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { useQuery } from "../../../../../../hooks/useQuery";
import { Disclaimer } from "./Disclaimer";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { Retry, Status } from "../../../../../../components/retry/Retry";
import { KfWithdrawalContext } from "./KfWithdrawalContext";
import { ROUTES } from "./WithdrawalKfRequestPage";
import {
  dataDanica,
  WithdrawalKFAccount,
} from "../../../../../../data/dataDanica";
import { UserContext } from "../../../../../../context/UserContext";
import { LOGIN_SWEDEN_PAGE_URL } from "../../../LoginPage";
import { LysaAccountSelectionCard } from "../../../../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";

export interface QueryParams {
  accountId?: InvestmentAccountId;
}

function getError(
  selectedWithdrawalAccount?: WithdrawalKFAccount,
  worth?: number
) {
  if (!selectedWithdrawalAccount || typeof worth === "undefined") {
    return null;
  }

  if (worth === 0) {
    return (
      <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
        <div>
          <TranslatedText id="withdrawalRequest.from.means" />
        </div>
      </Snackbar>
    );
  }

  if (selectedWithdrawalAccount.pendingWithdrawal) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <div>
          <TranslatedText id="withdrawalRequest.from.withdrawal" />
        </div>
      </Snackbar>
    );
  }

  if (selectedWithdrawalAccount.blockedByRecentWithdrawal) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <div>
          <TranslatedText id="withdrawalRequest.from.error.recent-withdrawal" />
        </div>
      </Snackbar>
    );
  }

  if (selectedWithdrawalAccount.pendingMove) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <div>
          <TranslatedText id="withdrawalRequest.from.error.pending-move" />
        </div>
      </Snackbar>
    );
  }

  return null;
}

export const From: React.FunctionComponent = () => {
  const kfWithdrawalContext = useContext(KfWithdrawalContext);
  const setKfWithdrawalState = kfWithdrawalContext.setState;
  const history = useHistory();
  const query = useQuery<QueryParams>();
  const formRef = React.useRef<LysaFormRef>();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [accounts, setAccounts] = useState<InvestmentAccount[]>([]);
  const [withdrawalKFAccounts, setWithdrawalKFAccounts] = useState<
    WithdrawalKFAccount[]
  >([]);
  const userContext = useContext(UserContext);
  const user = userContext.state;
  const location = useLocation<WithdrawalKFAccount>();

  const selectedAccount = accounts.find(
    (acc) => acc.accountId === kfWithdrawalContext.state.from?.accountId
  );

  const availableAccounts = useMemo(
    () =>
      accounts.filter(({ accountId }) =>
        withdrawalKFAccounts.some((account) => account.accountId === accountId)
      ),
    [accounts, withdrawalKFAccounts]
  );

  const load = useCallback(() => {
    if (accounts && withdrawalKFAccounts) {
      return;
    }
    Promise.all([
      dataAccounts.getAccounts(),
      dataDanica.getWithdrawalKFAccounts(),
    ])
      .then((data) => {
        const [requestAccounts, requestWithdrawalKFAccounts] = data;
        if (query.accountId) {
          const querySelectedAccount = requestWithdrawalKFAccounts.find(
            (item) => item.accountId === query.accountId
          );

          if (!selectedAccount) {
            if (querySelectedAccount) {
              setKfWithdrawalState({
                from: querySelectedAccount,
              });
              history.replace(getNavLink(ROUTES.TO));
            }
          } else {
            if (requestWithdrawalKFAccounts.length === 1) {
              setKfWithdrawalState({
                from: requestWithdrawalKFAccounts[0],
              });
            }
          }
        }

        setAccounts(requestAccounts);
        setWithdrawalKFAccounts(requestWithdrawalKFAccounts);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [
    accounts,
    withdrawalKFAccounts,
    query.accountId,
    selectedAccount,
    setKfWithdrawalState,
    history,
  ]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 500);
  }, [load]);

  useEffect(load, [load]);

  useEffect(() => {
    if (
      typeof location.state === "undefined" ||
      typeof withdrawalKFAccounts === "undefined"
    ) {
      return;
    }
    setKfWithdrawalState({
      from: withdrawalKFAccounts.find(
        (account) => account.accountId === location.state.accountId
      ),
    });
  }, [location.state, setKfWithdrawalState, withdrawalKFAccounts]);

  const next = useCallback(() => {
    if (!formRef.current?.isValid) {
      return;
    }

    const hasError = !!getError(
      kfWithdrawalContext.state.from,
      selectedAccount?.worth
    );

    if (hasError) {
      return;
    }

    kfWithdrawalContext.setState({
      account: selectedAccount,
    });

    history.push(getNavLink(ROUTES.TO));
  }, [history, kfWithdrawalContext, selectedAccount]);

  if (!user.name) {
    return <Redirect to={generatePath(getNavLink(LOGIN_SWEDEN_PAGE_URL))} />;
  }

  if (!accounts.length) {
    return <div className="withdrawal-request-page-from" />;
  }

  const error = getError(
    kfWithdrawalContext.state.from,
    selectedAccount?.worth
  );

  return (
    <div className="withdrawal-request-page-from">
      <Typography type="h1">
        <TranslatedText id="withdrawalRequest.from.header" />
      </Typography>

      <Snackbar type={SNACKBAR_TYPES.INFO} icon>
        <TranslatedText id="withdrawalRequest.from.ingress" />
      </Snackbar>

      <Retry status={status} retry={retry}>
        <Form lysaFormRef={formRef} onSubmit={next}>
          <LysaAccountSelectionCard
            accounts={availableAccounts}
            selectedAccount={availableAccounts?.find(
              (account) =>
                account.accountId === kfWithdrawalContext.state.from?.accountId
            )}
            onChange={(lysaAccount) => {
              const withdrawalAccount = withdrawalKFAccounts.find(
                (withdrawalAccount) =>
                  withdrawalAccount.accountId === lysaAccount.accountId
              );
              if (withdrawalAccount) {
                kfWithdrawalContext.setState({
                  from: withdrawalAccount,
                });
              }
            }}
            legend={
              <TranslatedText id="withdrawalRequest.from.accountSelection" />
            }
          />
          {error}
          <Button
            block
            type="submit"
            label={<TranslatedText id="withdrawalRequest.kf.from.next" />}
          />
        </Form>
      </Retry>
      <Disclaimer />
    </div>
  );
};
