import { useContext } from "react";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { AUTOGIRO_DEPOSIT_PAGE_URL } from "./Autogiro/deposit/AutogiroDepositPage";
import { SWISH_DEPOSIT_PAGE_URL } from "./swish/SwishDepositPage";
import { BANKGIRO_DEPOSIT_PAGE_URL } from "./bankgiro/BankgiroDepositStory";
import { UserContext } from "../../../../context/UserContext";
import { LegalEntityType } from "../../../../data/dataLogin";
import { useIntl } from "react-intl";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import {
  DepositMethod,
  RecommendableChannel,
  Recommendation,
} from "../../../../pages/deposits/overview/Recommendation";
import { Description } from "../../../../components/deposits/depositCard/items/Description";
import { TranslatedText } from "../../../../components/TranslatedText";
import { SwishIcon } from "./icons/SwishIcon";
import { ISK_TRANSFER_URL } from "../iskTransfer/IskTransferStory";
import { ExpandableContent } from "../../../../components/expandableContent/ExpandableContent";
import { MONTHLY_DEPOSITS_URL } from "../../../../pages/deposits/monthly/create/MonthlyStory";
import { AutogiroIcon } from "./icons/AutogiroIcon";
import { BankgiroIcon } from "./icons/BankgiroIcon";
import { IskTransferIcon } from "./icons/IskTransferIcon";
import { MonthlyDepositIcon } from "./icons/MonthlyDepositIcon";

export function DepositsOverviewWrapper() {
  const userContext = useContext(UserContext);
  const autogiroAlt = useAutogiroItem(true);
  const swishAlt = useSwishItem();
  const bankgiroAlt = useBankgiroItem();
  const iskTransferalt = useIskTransferItem();
  const monthlyDepositAlt = useMonthlyDepositItem();

  return (
    <div className="deposits-overview-wrapper-sweden">
      <Recommendation
        alternatives={
          userContext.state.legalEntityType === LegalEntityType.PERSON
            ? [
                autogiroAlt,
                swishAlt,
                bankgiroAlt,
                monthlyDepositAlt,
                iskTransferalt,
              ]
            : [autogiroAlt, bankgiroAlt]
        }
      />
    </div>
  );
}

function useAutogiroItem(isRecommended = false): DepositMethod {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  return {
    alternative: {
      header: intl.formatMessage({
        id: "sweden.deposits.overview.autogiro.header",
      }),
      description: (
        <Description
          isRecommended={isRecommended}
          text={intl.formatMessage({
            id: "sweden.deposits.overview.autogiro.body",
          })}
        />
      ),
      value: RecommendableChannel.AUTOGIRO,
      logo: <AutogiroIcon size={40} />,
      expandableContent: (
        <ExpandableContent>
          <ul>
            <li>
              <TranslatedText
                id="sweden.deposits.overview.autogiro.list.item-1"
                values={{
                  minDeposit: intl.formatNumber(1_000, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }),
                }}
              />
            </li>
            <li>
              <TranslatedText id="sweden.deposits.overview.autogiro.list.item-2" />
            </li>
            <li>
              <TranslatedText id="sweden.deposits.overview.autogiro.list.item-3" />
            </li>
          </ul>
        </ExpandableContent>
      ),
    },
    link: getNavLink(AUTOGIRO_DEPOSIT_PAGE_URL),
  };
}

function useSwishItem(isRecommended = false): DepositMethod {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  return {
    alternative: {
      header: intl.formatMessage({
        id: "sweden.deposits.overview.swish.header",
      }),
      description: (
        <Description
          isRecommended={isRecommended}
          text={intl.formatMessage({
            id: "sweden.deposits.overview.swish.body",
          })}
        />
      ),
      value: RecommendableChannel.SWISH,
      logo: <SwishIcon size={40} />,
      expandableContent: (
        <ExpandableContent>
          <ul>
            <li>
              <TranslatedText
                id="sweden.deposits.overview.swish.list.item-1"
                values={{
                  minDeposit: intl.formatNumber(1_000, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }),
                  maxDeposit: intl.formatNumber(150_000, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }),
                }}
              />
            </li>
            <li>
              <TranslatedText id="sweden.deposits.overview.swish.list.item-2" />
            </li>
          </ul>
        </ExpandableContent>
      ),
    },
    link: getNavLink(SWISH_DEPOSIT_PAGE_URL),
  };
}

function useBankgiroItem(isRecommended = false): DepositMethod {
  const intl = useIntl();

  return {
    alternative: {
      header: intl.formatMessage({
        id: "sweden.deposits.overview.bankgiro.header",
      }),
      description: (
        <Description
          isRecommended={isRecommended}
          text={intl.formatMessage({
            id: "sweden.deposits.overview.bankgiro.body",
          })}
        />
      ),
      value: RecommendableChannel.BANKGIRO,
      logo: <BankgiroIcon size={40} />,
      expandableContent: (
        <ExpandableContent>
          <ul>
            <li>
              <TranslatedText id="sweden.deposits.overview.bankgiro.list.item-1" />
            </li>
            <li>
              <TranslatedText id="sweden.deposits.overview.bankgiro.list.item-2" />
            </li>
          </ul>
        </ExpandableContent>
      ),
    },
    link: getNavLink(BANKGIRO_DEPOSIT_PAGE_URL),
  };
}

function useIskTransferItem(isRecommended = false): DepositMethod {
  const intl = useIntl();

  return {
    alternative: {
      header: intl.formatMessage({
        id: "sweden.deposits.overview.isk-transfer.header",
      }),
      description: (
        <Description
          isRecommended={isRecommended}
          text={intl.formatMessage({
            id: "sweden.deposits.overview.isk-transfer.body",
          })}
        />
      ),
      value: RecommendableChannel.ISK_TRANSFER,
      logo: <IskTransferIcon size={40} />,
      expandableContent: (
        <ExpandableContent>
          <ul>
            <li>
              <TranslatedText id="sweden.deposits.overview.isk-transfer.list.item-1" />
            </li>
            <li>
              <TranslatedText id="sweden.deposits.overview.isk-transfer.list.item-2" />
            </li>
          </ul>
        </ExpandableContent>
      ),
    },
    link: getNavLink(ISK_TRANSFER_URL),
  };
}

function useMonthlyDepositItem(isRecommended = false): DepositMethod {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  return {
    alternative: {
      header: intl.formatMessage({
        id: "sweden.deposits.overview.monthly-deposit.header",
      }),
      description: (
        <Description
          isRecommended={isRecommended}
          text={intl.formatMessage({
            id: "sweden.deposits.overview.monthly-deposit.body",
          })}
        />
      ),
      value: RecommendableChannel.MONTHLY_DEPOSIT,
      logo: <MonthlyDepositIcon size={40} />,
      expandableContent: (
        <ExpandableContent>
          <ul>
            <li>
              <TranslatedText id="sweden.deposits.overview.monthly-deposit.list.item-1" />
            </li>
            <li>
              <TranslatedText
                id="sweden.deposits.overview.monthly-deposit.list.item-2"
                values={{
                  minDeposit: intl.formatNumber(200, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }),
                }}
              />
            </li>
          </ul>
        </ExpandableContent>
      ),
    },
    link: getNavLink(MONTHLY_DEPOSITS_URL),
  };
}
