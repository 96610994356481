import React, { VoidFunctionComponent } from "react";
import { useDocumentInformation } from "../../../../../../hooks/useDocumentInformation";

interface Props {
  documentName: string;
}

export const DocName: VoidFunctionComponent<Props> = ({ documentName }) => {
  const documentInformation = useDocumentInformation(
    documentName.replace(".md", "")
  );
  return <React.Fragment>{documentInformation?.title}</React.Fragment>;
};
