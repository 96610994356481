import { Button } from "@lysaab/ui-2";
import React, { VoidFunctionComponent } from "react";
import { generatePath, Link } from "react-router-dom";
import { PendingDeposit } from "../../../../../components/deposits/pendingDeposits/PendingDepositCard";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  InvestmentAccount,
  AccountType,
  getAccountAmount,
  isInvestmentAccount,
  isSavingsAccount,
  SavingsAccount,
} from "../../../../../data/dataAccounts";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useIsReadOnly } from "../../../../../hooks/useIsReadOnly";
import { BASE_ROUTES } from "../../../../closeAccountStory/CloseAccountStory";
import { BASE_ROUTES as CLOSE_SAVINGS_ACCOUNT_ROUTES } from "../../../../../countries/sweden/pages/closeSavingsAccountStory/CloseSavingsAccountStory";

import "./CloseAccountRow.scss";

interface Props {
  account: InvestmentAccount | SavingsAccount;
  isOnlyOneInvestmentAccount: boolean;
  isLastCorporateVPAccount: boolean;
  pendingDeposits: PendingDeposit[];
  hasIncomingInternalTransfer: boolean;
}

export const CloseAccountRow: VoidFunctionComponent<Props> = ({
  account,
  isOnlyOneInvestmentAccount,
  isLastCorporateVPAccount,
  pendingDeposits,
  hasIncomingInternalTransfer,
}) => {
  const isReadonly = useIsReadOnly();
  const isMoneyOnAccount = getAccountAmount(account) > 0;
  const isPendingDeposit = pendingDeposits.some(
    (deposit) => deposit.accountId === account.accountId
  );
  const isKapitalForsakring =
    isInvestmentAccount(account) && account.type === AccountType.DANICA_KF;

  const accountIsSavingsAccount = isSavingsAccount(account);

  const isCloseDisabledForInvestmentAccount =
    isMoneyOnAccount ||
    isOnlyOneInvestmentAccount ||
    isPendingDeposit ||
    isKapitalForsakring ||
    isLastCorporateVPAccount ||
    isReadonly ||
    hasIncomingInternalTransfer;

  const isCloseDisabledForSavingsAccount =
    isMoneyOnAccount || isPendingDeposit || hasIncomingInternalTransfer;

  return (
    <div className="close-account-row">
      <div className="flex">
        <span>{account.name}</span>
        {accountIsSavingsAccount ? (
          isCloseDisabledForSavingsAccount ? (
            <Button
              variant="secondary"
              size="small"
              disabled
              inline
              label={<TranslatedText id="endInvestment.closeAccount.close" />}
            />
          ) : (
            <Button
              component={Link}
              to={getNavLink(
                generatePath(
                  CLOSE_SAVINGS_ACCOUNT_ROUTES.CLOSE_SAVINGS_ACCOUNT_CONFIRMATION,
                  {
                    accountId: account.accountId,
                  }
                )
              )}
              inline
              variant="secondary"
              size="small"
              label={<TranslatedText id="endInvestment.closeAccount.close" />}
            />
          )
        ) : isCloseDisabledForInvestmentAccount ? (
          <Button
            variant="secondary"
            size="small"
            disabled
            inline
            label={<TranslatedText id="endInvestment.closeAccount.close" />}
          />
        ) : (
          <Button
            component={Link}
            to={getNavLink(
              generatePath(BASE_ROUTES.CLOSE_ACCOUNT_CONFIRMATION, {
                accountId: account.accountId,
              })
            )}
            inline
            variant="secondary"
            size="small"
            label={<TranslatedText id="endInvestment.closeAccount.close" />}
          />
        )}
      </div>
      {!accountIsSavingsAccount && isOnlyOneInvestmentAccount ? (
        <p>
          <TranslatedText id="endInvestment.closeAccount.isOnlyOneAccount" />
        </p>
      ) : (
        <p>
          {(isMoneyOnAccount &&
            (accountIsSavingsAccount ? (
              <TranslatedText id="endInvestment.closeAccount.savingsAccount.isMoneyOnAccount" />
            ) : (
              <TranslatedText id="endInvestment.closeAccount.isMoneyOnAccount" />
            ))) ||
            (isPendingDeposit && (
              <TranslatedText id="endInvestment.closeAccount.isPendingDeposit" />
            )) ||
            (isKapitalForsakring && (
              <TranslatedText id="endInvestment.closeAccount.isKapitalForsakring" />
            )) ||
            (isLastCorporateVPAccount && (
              <TranslatedText id="endInvestment.closeAccount.isLastVPAccount" />
            )) ||
            (hasIncomingInternalTransfer && (
              <TranslatedText id="endInvestment.closeAccount.hasIncomingInternalTransfer" />
            ))}
        </p>
      )}
    </div>
  );
};
