import { Alternative } from "@lysaab/ui-2";
import React, { useContext, VoidFunctionComponent } from "react";
import {
  SavingsHorizonLength,
  NeedEarlierProbability,
} from "../../../data/dataInvestments";
import { Horizon } from "../../../pageComponents/horizon/Horizon";
import { CreateAccountContext } from "../../../pages/createAccount/CreateAccountContext";

interface Props {
  next: () => void;
}

export const HorizonWrapper: VoidFunctionComponent<Props> = ({ next }) => {
  const createAccountContext = useContext(CreateAccountContext);

  return (
    <Horizon
      horizonLength={createAccountContext.state.savingsHorizon}
      setHorizonLength={(savingsHorizon?: Alternative<SavingsHorizonLength>) =>
        createAccountContext.setState({ savingsHorizon: savingsHorizon?.value })
      }
      horizonProbability={createAccountContext.state.needEarlier}
      setHorizonProbability={(
        needEarlier?: Alternative<NeedEarlierProbability>
      ) => createAccountContext.setState({ needEarlier: needEarlier?.value })}
      next={next}
      accountType={createAccountContext.state.accountType}
    />
  );
};
