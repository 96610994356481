import {
  dataCustomerTrackingService,
  TrackerEvent,
  TrackRequest,
} from "../../data/dataCustomerTracking";
import { getLoginCookie } from "../../utils/LoginCookie";
import { YearlyReviewTrack } from "./events/YearlyReview";

const DEBOUNCE_INTERVAL = 500;

interface DefaultTrack {
  event:
    | TrackerEvent.DEPOSIT
    | TrackerEvent.WITHDRAWAL
    | TrackerEvent.DELETE_WITHDRAWAL
    | TrackerEvent.USER_AGENT
    | TrackerEvent.SWITCH_ENTITY
    | TrackerEvent.CHANGE_ALLOCATION
    | TrackerEvent.INTERNAL_TRANSFER
    | TrackerEvent.PAUSE_MONTHLY
    | TrackerEvent.RESET_MONTHLY
    | TrackerEvent.ADD_MONTHLY
    | TrackerEvent.ADD_MONTHLY_ACCOUNT_CHOICE
    | TrackerEvent.ADD_MONTHLY_EXTERNAL_ACCOUNT
    | TrackerEvent.ADD_MONTHLY_KLARNA_BANK
    | TrackerEvent.ADD_MONTHLY_KLARNA_ACCOUNT
    | TrackerEvent.UPDATE_MONTHLY
    | TrackerEvent.DELETE_MONTHLY
    | TrackerEvent.BANK_SELECTION
    | TrackerEvent.ACCOUNT_NAME_CHANGE
    | TrackerEvent.ERROR
    | TrackerEvent.RETRY
    | TrackerEvent.INITIATE_SWISH
    | TrackerEvent.DEPOSIT_KLARNA
    | TrackerEvent.LOADED_ACCOUNTS
    | TrackerEvent.PAGE
    | TrackerEvent.YEARLY_REVIEW_CRS
    | TrackerEvent.YEARLY_REVIEW_CUSTOMER_KYC;
  message?: string | { [key: string]: string | number };
}

type TrackProps = YearlyReviewTrack | DefaultTrack;

type TrackWithMessage = {
  message: string | { [key: string]: string | number };
};

function isTrackWithMessage(track: any): track is TrackWithMessage {
  return (track as TrackWithMessage).message !== undefined;
}

class EventTrackerImpl {
  private seq = 0;
  private trackQueue: TrackRequest[] = [];
  private debounceInterval: NodeJS.Timeout | undefined;

  public track = (track: TrackProps) => {
    const isSignedIn = !!getLoginCookie();
    if (!isSignedIn) {
      return;
    }

    const message = isTrackWithMessage(track) ? track.message : undefined;

    if (this.seq === 0) {
      this.sendUserAgent(isSignedIn);
    }
    this.seq++;
    let messageString: string | undefined;
    if (message && typeof message !== "string") {
      try {
        messageString = JSON.stringify(message);
      } catch (e) {
        messageString = "Unknown error";
      }
    } else {
      messageString = message;
    }
    this.trackQueue.push({
      seq: this.seq,
      uri: window.location.pathname,
      event: track.event,
      message: messageString,
    });
    if (this.debounceInterval) {
      clearInterval(this.debounceInterval);
    }
    this.debounceInterval = setTimeout(this.sendTracking, DEBOUNCE_INTERVAL);
  };

  private sendTracking = () => {
    const queue = [...this.trackQueue];
    this.trackQueue = [];
    dataCustomerTrackingService
      .sendTracking(queue)
      .catch(() => console.log("Failed to send events"));
  };

  public sendUserAgent = (isSignedIn: boolean) => {
    if (!isSignedIn) {
      return;
    }
    const message = {
      interface: "web",
      userAgent: navigator.userAgent,
      hash: process.env.REACT_APP_GIT_HASH,
    };
    this.trackQueue.push({
      seq: 0,
      uri: window.location.pathname,
      event: TrackerEvent.CLIENT_INFO,
      message: JSON.stringify(message),
    });
  };

  public reset = () => {
    this.seq = 0;
  };
}

export const EventTracker = new EventTrackerImpl();
