import React, { useState, useEffect, useCallback } from "react";
import { useRouteMatch, Redirect } from "react-router-dom";
import { Page, PageHeader } from "../Page";
import { NOT_FOUND_URL } from "../NotFoundPage";
import { getNavLink } from "../../hooks/useCountryUrls";
import {
  AccountType,
  CompoundAccount,
  InvestmentAccountId,
  isSavingsAccount,
} from "../../data/dataAccounts";
import { Retry, Status } from "../../components/retry/Retry";
import { dataAccounts } from "../../data/dataAccounts";
import { GraphCard } from "../overview/GraphCard";
import { Transactions } from "./Transactions";
import { AccountName } from "../../components/accountName/AccountName";
import { Monthly } from "./Monthly";
import { Fees } from "./fees/Fees";
import { Positions } from "./positions/Positions";
import { Docs } from "./Docs";
import { ChangeName } from "./ChangeName";
import { AccountActions } from "./AccountActions";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { MONTHLY_OVERVIEW_PAGE } from "../deposits/monthly/overview/MonthlyOverview";
import { CloseAccount } from "./CloseAccount";
import "./AccountPage.scss";
import { ShareAccountCard } from "./ShareAccountCard";
import { SHARE_ACCOUNT_URL } from "../shareAccountStory/ShareAccountStory";
import { FormattedMessage } from "react-intl";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { useAdviseAccount } from "../../hooks/useAdviseAccount";
import { useApi } from "@lysaab/ui-2";
import { dataInvestments } from "../../data/dataInvestments";
import { isSuccessful } from "@lysaab/ui-2/network/useApi";
import { AccountFocus } from "./AccountFocus";
import { AccountAllocation } from "./AccountAllocation";
import { InsuranceInformation } from "./InsuranceInformation";

export const ACCOUNT_PAGE_URL = "/account/:accountId";

export function AccountPage() {
  const match = useRouteMatch<{
    country: string;
    accountId: InvestmentAccountId;
  }>({
    path: "/:country/account/:accountId",
  });
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [accounts, setAccounts] = useState<CompoundAccount[]>([]);
  const [advice] = useAdviseAccount(match?.params.accountId);
  const eligibility = useApi(dataInvestments.getEligibility);
  const [numberOfInvestmentAccounts, setNumberOfInvestmentAccounts] =
    useState<number>(1);

  const load = useCallback(() => {
    dataAccounts
      .getAllAccounts()
      .then((data) => {
        setNumberOfInvestmentAccounts(data.investmentAccounts.length);
        setAccounts([...data.investmentAccounts, ...data.savingsAccounts]);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  const retry = useCallback(() => {
    dataAccounts.clearGetAccounts();
    setTimeout(load, 500);
  }, [load]);

  const onUpdate = useCallback(() => {
    dataAccounts.clearGetAccounts();
    load();
  }, [load]);

  useEffect(load, [load]);

  const accountId = match?.params.accountId;
  let account: CompoundAccount | undefined;
  if (accounts.length > 0) {
    account = accounts.find((item) => item.accountId === accountId);
    if (!account) {
      return <Redirect to={getNavLink(NOT_FOUND_URL)} />;
    }
  }

  if (account && isSavingsAccount(account)) {
    return <Redirect to={getNavLink(NOT_FOUND_URL)} />;
  }

  return (
    <Page className="account-page">
      <Retry retry={retry} status={status}>
        <PageHeader>
          <h1>
            {account ? (
              <AccountName
                account={account}
                showAccountType={true}
                showAmount={false}
              />
            ) : null}
          </h1>
        </PageHeader>
        <GraphCard accounts={accounts} account={account} />
      </Retry>
      <AccountActions account={account} accounts={accounts} />
      <div className="account-page-grid">
        <GridRow>
          <RouteAwareToggle path={getNavLink(MONTHLY_OVERVIEW_PAGE)}>
            <GridCol xsmall={12} medium={6} large={4}>
              <Monthly account={account} />
            </GridCol>
          </RouteAwareToggle>

          <GridCol xsmall={12} medium={6} large={4}>
            <AccountFocus
              account={account}
              adviseAccount={advice}
              eligibility={
                isSuccessful(eligibility) ? eligibility.data : undefined
              }
            />
          </GridCol>

          <GridCol xsmall={12} medium={6} large={4}>
            <AccountAllocation account={account} adviseAccount={advice} />
          </GridCol>

          <GridCol xsmall={12} medium={6} large={4}>
            <Positions account={account} accounts={accounts} />
          </GridCol>

          <GridCol xsmall={12} medium={6} large={4}>
            <Fees account={account} accounts={accounts} />
          </GridCol>

          <GridCol xsmall={12} medium={6} large={4}>
            <Transactions account={account} accounts={accounts} />
          </GridCol>

          <RouteAwareToggle path={getNavLink(SHARE_ACCOUNT_URL)}>
            <GridCol xsmall={12} medium={6} large={4}>
              <section>
                <h2>
                  <FormattedMessage id="accountPage.sharedAccount.header" />
                </h2>
                <ShareAccountCard account={account} />
              </section>
            </GridCol>
          </RouteAwareToggle>
          {account?.type === AccountType.KF_SWE && (
            <GridCol xsmall={12} medium={6} large={4}>
              <InsuranceInformation account={account} />
            </GridCol>
          )}

          <GridCol xsmall={12} medium={6} large={4}>
            <Docs account={account} />
          </GridCol>

          <GridCol xsmall={12} medium={6} large={4}>
            <ChangeName account={account} onUpdate={onUpdate} />
          </GridCol>

          {accounts.length > 1 && numberOfInvestmentAccounts > 1 && (
            <CloseAccount account={account} />
          )}
        </GridRow>
      </div>
    </Page>
  );
}
