import React, { useRef, VoidFunctionComponent } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { SustainabilityImportance } from "../../../../../../../data/dataInvestments";
import { SustainabilityPreferenceQuestion } from "../../../../../../../pageComponents/sustainability/SustainabilityPreferenceQuestion";
import { useCreateKFAccount } from "../../KFAccountContext";

interface Props {
  next(): void;
}

export const Preference: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const [KFAccount, setKFAccount] = useCreateKFAccount();

  if (KFAccount.sustainability !== SustainabilityImportance.IMPORTANT) {
    return null;
  }

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <SustainabilityPreferenceQuestion
        sustainabilityPreference={KFAccount.sustainabilityPreference}
        setSustainabilityPreference={(sustainabilityPreference) =>
          setKFAccount({ sustainabilityPreference })
        }
      />
      <Button
        block
        type="submit"
        label={<FormattedMessage id="sweden.createAccount.preference.next" />}
      />
    </Form>
  );
};
