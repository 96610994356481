import React, {
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import { getAccountQuestions } from "../../../../../../data/dataInvestments";
import { ConfirmEsgUpdate } from "../../../../../../pageComponents/confirmEsgUpdate/ConfirmEsgUpdate";
import { Spinner } from "@lysaab/ui-2";
import {
  dataCreateAccount,
  isValidSuitabilityAssessmentRequest,
  SuitabilityAssessment,
  SuitabilityAssessmentRequest,
} from "../../../../../../data/dataCreateAccount";
import { useCreateKFAccount } from "../KFAccountContext";

interface Props {
  next: () => void;
}

export const ConfirmEsgUpdateWrapper: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const [advise, setAdvise] = useState<SuitabilityAssessment>();
  const localizationContext = useContext(LocalizationContext);
  const [KFAccount] = useCreateKFAccount();

  useEffect(() => {
    const data: Partial<SuitabilityAssessmentRequest> = {
      language: localizationContext.state.language,
      ...getAccountQuestions(KFAccount),
    };

    if (!isValidSuitabilityAssessmentRequest(data)) {
      throw new Error(
        "kf account - ConfirmEsgUpdateWrapper - not valid data in isValidAccountQuestions"
      );
    }

    dataCreateAccount.getKFSuitabilityAssessment(data).then((advise) => {
      if (!advise.esgResult.esgBestMatch) {
        return next();
      }
      setAdvise(advise);
    });
  }, [KFAccount, localizationContext.state.language, next]);

  if (!advise) {
    return <Spinner />;
  }

  return (
    <ConfirmEsgUpdate
      esg={advise.esgResult.esgAnswers}
      updatedEsg={advise.esgResult.esgBestMatch!}
      next={next}
    />
  );
};
