import React, { useCallback, useEffect, VoidFunctionComponent } from "react";
import { Spinner } from "@lysaab/ui-2";
import { useHistory } from "react-router";
import { useCreateKFAccount } from "../KFAccountContext";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../../../../pages/overview/OverviewPage";
import { EditAllocation } from "../../../../../../pageComponents/editAllocation/EditAllocation";
import { AccountType } from "../../../../../../data/dataAccounts";
interface Props {
  next: () => void;
  nextRiskWarning: () => void;
}

export const EditAllocationWrapper: VoidFunctionComponent<Props> = ({
  next,
  nextRiskWarning,
}) => {
  const [KFAccount, setKFAccount] = useCreateKFAccount();
  const history = useHistory();

  useEffect(() => {
    if (typeof KFAccount.advicedRisk === "undefined") {
      history.replace(getNavLink(OVERVIEW_PAGE_URL));
    }
  }, [KFAccount.advicedRisk, history]);

  const onNext = useCallback(() => {
    setKFAccount({
      takenRisk: KFAccount.allocationSelectedRisk,
    });
    next();
  }, [KFAccount.allocationSelectedRisk, next, setKFAccount]);

  const onNextRiskWarning = useCallback(() => {
    setKFAccount({
      takenRisk: KFAccount.allocationSelectedRisk,
    });
    nextRiskWarning();
  }, [KFAccount.allocationSelectedRisk, nextRiskWarning, setKFAccount]);

  if (!KFAccount.advicedRisk || !KFAccount.investmentType) {
    return <Spinner />;
  }

  return (
    <EditAllocation
      advisedRisk={KFAccount.advicedRisk}
      savingsHorizon={KFAccount.savingsHorizon}
      allocationSelectedRisk={KFAccount.allocationSelectedRisk}
      setAllocationSelectedRisk={(allocationSelectedRisk: number) =>
        setKFAccount({
          allocationSelectedRisk,
        })
      }
      allocationInvestment={
        (typeof KFAccount.allocationInvestment !== "undefined" &&
          parseInt(KFAccount.allocationInvestment, 10)) ||
        undefined
      }
      setAllocationInvestment={(allocationInvestment: number) =>
        setKFAccount({
          allocationInvestment: allocationInvestment.toString(),
        })
      }
      investmentType={KFAccount.investmentType}
      next={onNext}
      nextRiskWarning={onNextRiskWarning}
      accountType={AccountType.KF_SWE}
      insured={KFAccount.identificationNumber}
    />
  );
};
