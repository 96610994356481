import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { FlashContext, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { LocalizationContext } from "../../../context/LocalizationContext";
import {
  AccountQuestions,
  AdviceResponse,
  dataInvestments,
  getAccountQuestions,
  GetSuitabilityAssessmentRequest,
  isValidGetSuitabilityAssessmentRequest,
} from "../../../data/dataInvestments";
import {
  Advise,
  AdviceState as AdviseState,
} from "../../../pageComponents/advise/Advise";
import { CreateAccountContext } from "../../../pages/createAccount/CreateAccountContext";

const messages = defineMessages({
  retry: {
    id: "advice.wrapper.retry",
  },
  assessmentErrorFlash: {
    id: "advice.wrapper.assessment.error.flash",
  },
});

interface Props {
  next: () => void;
  navigateToEditAllocation: () => void;
  navigateToFees: () => void;
}

export const AdviseWrapper: VoidFunctionComponent<Props> = ({
  next,
  navigateToEditAllocation,
  navigateToFees,
}) => {
  const intl = useIntl();
  const createAccountContext = useContext(CreateAccountContext);
  const localizationContext = useContext(LocalizationContext);
  const pushFlash = useContext(FlashContext).pushFlash;
  const [loadingState, setLoadingState] = useState(AdviseState.INITIAL);
  const [advise, setAdvise] = useState<AdviceResponse>();
  const [retryMsg, setRetryMsg] = useState<string>();

  const createAccountContextState = createAccountContext.state;
  const setAccountContext = createAccountContext.setState;

  useEffect(() => {
    if (loadingState !== AdviseState.INITIAL) {
      return;
    }

    const data: Partial<GetSuitabilityAssessmentRequest> = {
      language: localizationContext.state.language,
      ...getAccountQuestions(createAccountContextState),
    };

    if (!isValidGetSuitabilityAssessmentRequest(data)) {
      throw new Error("AdviceWrapper - Missing data object");
    }

    setLoadingState(AdviseState.LOADING);
    dataInvestments
      .getNewAccountSuitability(data)
      .then((advise) => {
        setAccountContext({ advise });
        setAdvise(advise);
        setLoadingState(AdviseState.DONE);
      })
      .catch((error) => {
        setLoadingState(AdviseState.ERROR);
        //     // We don't get a status when the network is down
        if (!error.status || error.status >= 500) {
          setRetryMsg(intl.formatMessage(messages.retry));
        } else {
          pushFlash({
            type: SNACKBAR_TYPES.ERROR,
            text: intl.formatMessage(messages.assessmentErrorFlash),
          });
          return;
        }
      });
  }, [
    createAccountContextState,
    intl,
    loadingState,
    localizationContext.state.language,
    pushFlash,
    setAccountContext,
  ]);

  const onRetry = useCallback(() => {
    setRetryMsg(undefined);
    setLoadingState(AdviseState.INITIAL);
  }, []);

  const onNext = useCallback(() => {
    setAccountContext({
      takenRisk: createAccountContextState.advise?.advisedRisk,
    });
    next();
  }, [setAccountContext, createAccountContextState.advise?.advisedRisk, next]);

  if (!createAccountContextState.advise) {
    return <Spinner />;
  }

  let accountQuestions: Partial<AccountQuestions> | undefined;

  try {
    accountQuestions = getAccountQuestions(createAccountContextState);
  } catch {}

  if (!accountQuestions) {
    return null;
  }

  return (
    <Advise
      advise={advise}
      retryMsg={retryMsg}
      investmentType={createAccountContextState.advise.investmentType}
      retry={onRetry}
      next={onNext}
      navigateToEditAllocation={navigateToEditAllocation}
      navigateToFees={navigateToFees}
      accountQuestions={accountQuestions}
      accountType={createAccountContextState.accountType}
    />
  );
};
