import { API, cache, encode } from "@lysaab/ui-2";
import { DateTime } from "luxon";
import { ReactNode } from "react";
import { LegalEntityId } from "./dataUser";

export type ThreadId = string & { readonly isThreadId: true };

export enum MessagesAuthor {
  ADMIN = "ADMIN",
  CUSTOMER = "CUSTOMER",
}

export enum MessageSubject {
  HOW_LYSA_WORK = "HOW_LYSA_WORK",
  FEES = "FEES",
  ACCOUNTS_AND_ALLOCATION = "ACCOUNTS_AND_ALLOCATION",
  ROI = "ROI",
  DEPOSITS_WITHDRAWALS = "DEPOSITS_WITHDRAWALS",
  LIQUID_TRANSFER = "LIQUID_TRANSFER",
  FEEDBACK = "FEEDBACK",
  FUNDS = "FUNDS",
  OTHER = "OTHER",
  AML = "AML",
  INSURANCE = "INSURANCE",
}

export enum MessagesStatus {
  WAITING_FOR_CUSTOMER = "WAITING_FOR_CUSTOMER",
  WAITING_FOR_ADMIN = "WAITING_FOR_ADMIN",
  CLOSED = "CLOSED",
}

export interface Message {
  date: string;
  message: string | ReactNode;
  attachments: {
    id: number;
    filename: string;
  }[];
  author: MessagesAuthor;
  authorName?: string;
  tin?: string;
  /** Only in frontend, not from backend */
  isGenerated?: boolean;
}

export interface Thread {
  id: ThreadId;
  subject: MessageSubject;
  messages: Message[];
  status: MessagesStatus;
  readByCustomer: boolean;
}

interface MessageThreadResponse {
  id: ThreadId;
  subject: MessageSubject;
  messages: Message[];
  status: MessagesStatus;
  readByCustomer: boolean;
  createdBy: LegalEntityId;
  closeReason: CloseReason;
  /** UTC-date */
  lastMessage: string;
}

export enum MessagesReason {
  CLOSED_BY_CUSTOMER = "CLOSED_BY_CUSTOMER",
  ANSWERED = "ANSWERED",
}

export enum CloseReason {
  CLOSED_BY_CUSTOMER = "CLOSED_BY_CUSTOMER",
  CLOSED_BY_ADMIN = "CLOSED_BY_ADMIN",
  ANSWERED = "ANSWERED",
}

export interface SaveMessage {
  id: ThreadId;
  message: string;
  attachments: number[];
}

export interface CreateMessage {
  subject: MessageSubject;
  message: string;
  attachments: number[];
}

export interface DeleteMessage {
  id: ThreadId;
  reason: MessagesReason;
}

const DAY_IN_MS = 1000 * 60 * 60 * 24;

const clearGetMessages = () => {
  cache.delete("/messages");
};

export const dataMessages = {
  getMessages: () => {
    return API.get<Thread[]>(encode`/messages`);
  },

  setRead: (id: string) => {
    return API.post(encode`/messages/${id}/read`).then(clearGetMessages);
  },

  saveMessage: ({ id, message, attachments }: SaveMessage) => {
    return API.post(encode`/messages/${id}`, { message, attachments }).then(
      clearGetMessages
    );
  },

  createThread: (message: CreateMessage) => {
    return API.post<MessageThreadResponse>(encode`/messages`, message).then(
      (response) => {
        clearGetMessages();
        return response;
      }
    );
  },

  deleteMessage: ({ id, reason }: DeleteMessage) => {
    return API.delete<Thread>(encode`/messages/${id}`, { reason }).then(
      (response) => {
        clearGetMessages();
        return response;
      }
    );
  },

  deleteAttachment: (id: string) => {
    return API.delete(encode`/messages/attachment/${id}`).then(
      clearGetMessages
    );
  },

  getUnreadMessages: (messages: Thread[]) =>
    messages.filter((message) => !message.readByCustomer),

  getUnreadMessagesCount(messages: Thread[]) {
    return this.getUnreadMessages(messages).length;
  },

  getClosedMessages: (messages: Thread[]) =>
    messages.filter((message) => message.status === MessagesStatus.CLOSED),

  getActiveMessages: (messages: Thread[]) =>
    messages.filter((message) => message.status !== MessagesStatus.CLOSED),

  getDateLabel: (date: Date) => {
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0);
    if (date.getTime() > startOfToday.getTime() - DAY_IN_MS) {
      return DateTime.fromJSDate(date).toRelativeCalendar();
    }
    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED);
  },
};
