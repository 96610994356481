import React, { useCallback, useRef, useEffect } from "react";

import {
  BankIDStatus,
  FailedHintCode,
  PendingHintCode,
  Status,
} from "@lysaab/ui-2";
import { useCreateKFAccount } from "../KFAccountContext";
import { useParams, useHistory } from "react-router-dom";
import "./LysaSign.scss";
import {
  BankIdTransactionId,
  dataLife,
  InsuranceCreationToken,
} from "../../../../../../data/dataLife";
import { IntlShape, useIntl } from "react-intl";
import {
  signFailedMessages,
  signingMessages,
  signPendingMessages,
} from "./BankIdMessages";

interface Props {
  toOverview: (creationToken: InsuranceCreationToken) => void;
}

interface Params {
  transactionId: BankIdTransactionId;
  creationToken: InsuranceCreationToken;
}

const POLL_TIMER = 3000;

export const LysaSign: React.FunctionComponent<Props> = ({ toOverview }) => {
  const params = useParams<Params>();
  const started = useRef<boolean>(false);
  const history = useHistory();
  const [KFState, setKFState] = useCreateKFAccount();
  const intl = useIntl();

  const { creationToken, transactionId } = params;

  const poll = useCallback(
    (
      creationToken: InsuranceCreationToken,
      transactionId: BankIdTransactionId
    ) => {
      dataLife.pollSigning(creationToken, transactionId).then((response) => {
        setKFState({ signingStatus: response });
        if (response.status === Status.PENDING) {
          setTimeout(() => {
            poll(creationToken, transactionId);
          }, POLL_TIMER);
        } else if (response.status === Status.COMPLETE) {
          toOverview(creationToken);
        }
      });
    },
    [setKFState, toOverview]
  );

  useEffect(() => {
    if (started.current) {
      return;
    }
    started.current = true;
    poll(creationToken, transactionId);
  }, [creationToken, poll, transactionId]);

  if (!KFState.signingStatus) {
    return null;
  }

  return (
    <div className="create-account-corporation-lysa-sign">
      <BankIDStatus
        retry={() => {
          history.goBack();
        }}
        response={{
          hintCode:
            KFState.signingStatus.hintCode ??
            PendingHintCode.OUTSTANDINGTRANSACTION,
          status: KFState.signingStatus.status,
        }}
        getMessages={getMessages(intl)}
        getPendingMessages={getPendingMessages(intl)}
        getFailedMessages={getFailedMessages(intl)}
        autostarttoken={KFState.autostartToken}
      />
    </div>
  );
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(signingMessages.qrInfo1),
      qrInfo2: intl.formatMessage(signingMessages.qrInfo2),
      qrInfo3: intl.formatMessage(signingMessages.qrInfo3),
      divider: intl.formatMessage(signingMessages.divider),
      buttonOpen: intl.formatMessage(signingMessages.buttonOpen),
      buttonErrorHeader: intl.formatMessage(signingMessages.buttonErrorHeader),
      buttonRetry: intl.formatMessage(signingMessages.buttonRetry),
    };
  };
}

function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(signPendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(signFailedMessages[hintCode]);
}
