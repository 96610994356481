import { RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import React from "react";
import { useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { useCreateKFAccount } from "../../KFAccountContext";

export const HasNeed = () => {
  const intl = useIntl();
  const [KFAccount, setKFAccount] = useCreateKFAccount();

  return (
    <RadioGroup
      header={
        <React.Fragment>
          <TranslatedText id="sweden.kf.intro.need.label.intro" />
          <ul>
            <li>
              <TranslatedText id="sweden.kf.intro.need.label.item-1" />
            </li>
            <li>
              <TranslatedText id="sweden.kf.intro.need.label.item-2" />
            </li>
          </ul>
        </React.Fragment>
      }
      alternatives={[
        {
          text: intl.formatMessage({ id: "sweden.kf.intro.need.yes" }),
          value: true,
        },
        {
          text: intl.formatMessage({ id: "sweden.kf.intro.need.no" }),
          value: false,
        },
      ]}
      value={KFAccount.hasNeed}
      onChange={(value) =>
        setKFAccount({
          hasNeed: value,
        })
      }
      validators={[
        new RequiredValidator(
          intl.formatMessage({ id: "sweden.kf.intro.need.required" })
        ),
      ]}
    />
  );
};
