import React, { VoidFunctionComponent } from "react";
import { AnimateHeight } from "../animateHeight/AnimateHeight";
import { AccountType } from "../../data/dataAccounts";
import { SNACKBAR_TYPES, Snackbar } from "@lysaab/ui-2";
import { Link } from "react-router-dom";
import { getNavLink } from "../../hooks/useCountryUrls";
import { CREATE_ACCOUNT_CORPORATION_PAGE_URL } from "../../countries/sweden/pages/corporation/kf/CreateAccountCorporationPage";

const MIN_RECOMMENDED_ALLOCATION = 20;

interface Props {
  accountType: AccountType;
  risk: number;
  advicedRisk: number;
  onClick?: () => void;
}

export const KfAllocationDissuasion: VoidFunctionComponent<Props> = ({
  accountType,
  advicedRisk,
  risk,
  onClick,
}) => {
  if (
    accountType !== AccountType.DANICA_KF &&
    accountType !== AccountType.KF_SWE
  ) {
    return null;
  }

  return (
    <AnimateHeight
      isOpen={risk < MIN_RECOMMENDED_ALLOCATION && risk !== advicedRisk}
    >
      <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
        <div>
          <b>Avrådan på grund av Lysas roll som försäkringsförmedlare:</b>{" "}
          eftersom kapitalförsäkring är en schablonbeskattad sparform bör
          spararen ha en investering som över tid kan förväntas leverera
          avkastning som innebär att fördelen med schablonbeskattning
          tillgodogörs. Eftersom du valt en målfördelning som understiger{" "}
          <b>{MIN_RECOMMENDED_ALLOCATION} %</b> aktieinnehav är det möjligt att
          företaget inte kan tillgodogöra sig fördelen med en
          schablonbeskattning. Företaget kan istället öppna en värdepappersdepå
          hos Lysa.
          <br />
          <b>
            <Link
              to={getNavLink(CREATE_ACCOUNT_CORPORATION_PAGE_URL)}
              onClick={() => {
                onClick?.();
              }}
            >
              Öppna värdepappersdepå
            </Link>
          </b>
        </div>
      </Snackbar>
    </AnimateHeight>
  );
};
